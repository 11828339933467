export const it = {
  invoice: {
    address: "Indirizzo",
    amount: "Importo",
    amountError: "Inserire un importo valido",
    atecoCode: "Codice ATECO",
    broker: "Intermediario",
    certifiedEmail: "E-mail certificata",
    certifiedEmailError: "Inserire un indirizzo e-mail valido o un codice destinatario",
    cigCode: "Codice CIG",
    code: "Identificativo",
    company: "Azienda",
    companyTotals: "Totali per azienda",
    contractCode: "Identificativo contratto",
    costRows: "Costi",
    createInvoice: "Crea fattura",
    cupCode: "Codice CUP",
    date: "Data",
    dateError: "La data non è valida o non è coerente con la numerazione",
    deadline: "Scadenza",
    deadlineError: "Selezionare una data di scadenza valida",
    deadlines: "Scadenze di pagamento",
    deadlinesError: "La somma degli importi delle scadenze deve essere uguale al totale della fattura",
    depositRow: "Acconto da fattura {code} del {date}",
    detail: "Dettagli",
    details: {
      examination: "{type} {code} del {date} di {employee}",
      examinationPaper: "{type} del {date} di {employee}",
      externalTest: "Accertamento esterno {code} del {date} di {employee}",
      externalTestPaper: "Accertamento esterno del {date} di {employee}",
      groupedExternalTest: "Accertamenti esterni di {employee}",
      testType: "Accertamento nella visita medica {code} del {date} di {employee}",
      testTypePaper: "Accertamento nella visita medica del {date} di {employee}",
      service: "{type} {code} del {date} di {doctor}",
      servicePaper: "{type} del {date} di {doctor}",
      survey: "Questionario nella visita medica {code} del {date} di {employee}",
      surveyPaper: "Questionario nella visita medica del {date} di {employee}",
    },
    detailsTitle: "Dettagli riga",
    discount: "Sconto",
    discountError: "Inserire un valore di sconto valido",
    examinations: "Visite mediche",
    externalTests: "Accertamenti esterni",
    fiscalCode: "Codice fiscale",
    from: "dal",
    invoice: "Fattura",
    invoiceEmail: "E-mail fatturazione",
    invoiceEmailError: "Inserire un indirizzo e-mail valido",
    invoices: "Fatture",
    invoicingType: "Tipo fatturazione",
    invoicingTypes: {
      byBroker: "Per intermediario",
      byCompany: "Per azienda",
    },
    isVatApplied: "IVA applicata",
    isVatAppliedError: "Selezionare un valore",
    notes: "Note",
    orderCode: "Codice commessa",
    paid: "Pagata",
    paper: {
      beforeDate: "entro il",
      code: "Numero",
      date: "Data",
      deadlines: "Scadenze di pagamento",
      description: "Descrizione",
      detailTitle: "Dettagli fattura n. {code} del {date}",
      discount: "Sconto",
      fiscalCodePrefix: "C.F.",
      grandTotal: "Totale importo",
      notes: "Note",
      paymentDetails: "IBAN {iban} intestato a {holder} presso {bank}",
      price: "Prezzo",
      reference: "Riferimento",
      quantity: "Quantità",
      stamp: "Bollo (es. art 15)",
      stampText: "Assolvimento dell'imposta ai sensi del D.M. 17/6/2014",
      subtotal: "Totale imponibile",
      subtotalVatExempted: "Imponibile IVA esente",
      subtotalVatSubjected: "Imponibile IVA",
      title: "Fattura n. {code} del {date}",
      total: "Totale",
      type: "Tipo documento",
      types: {
        creditNote: "Nota di credito",
        deposit: "Acconto",
        invoice: "Fattura",
      },
      vat: "IVA",
      vatCodePrefix: "P.IVA",
      vatExemptionText: "Le prestazioni sanitarie sono IVA esenti ai sensi dell'art. 10, comma 1, n. 18 del D.P.R. 633/72 e s.m.i.",
      withHoldingTax: "Ritenuta d'acconto",
    },
    period: "Periodo di riferimento",
    periodList: "Periodo",
    price: "Prezzo",
    priceError: "Inserire un prezzo valido",
    priceRows: "Importi",
    province: "Provincia",
    quantity: "Quantità",
    quantityError: "Inserire una quantità valida",
    recipient: "Destinatario",
    recipientCode: "Codice destinatario",
    recipientCodeError: "Inserire codice destinatario o PEC",
    recipientData: "Intestazione",
    recipientName: "Ragione sociale",
    recipientNameError: "Inserire una ragione sociale valida",
    reference: "Riferimento",
    referenceText: "Testo riferimento",
    referencesList: "Riferimenti",
    rowDescription: "Descrizione",
    rowDescriptionError: "Inserire una descrizione valida",
    rowOwner: "Soggetto",
    rowOwnerError: "Selezionare un soggetto",
    selectedExaminations: "Visite mediche selezionate",
    selectedExternalTests: "Accertamenti esterni selezionati",
    selectedServices: "Prestazioni selezionate",
    services: "Prestazioni",
    serviceWithoutPrice: "Prestazione {service} senza prezzo",
    stamp: "Bollo",
    state: "Stato",
    states: {
      draft: "Bozza",
      emitted: "Emessa",
      sent: "Inviata",
      paid: "Pagata",
    },
    subtotalVatExempted: "Imponibile IVA esente",
    subtotalVatSubjected: "Imponibile IVA",
    subtotal: "Totale imponibile",
    testTypeWithoutPrice: "Accertamento {testType} senza prezzo",
    total: "Importo totale",
    to: "al",
    town: "Comune",
    type: "Tipo documento",
    types: {
      creditNote: "Nota di credito",
      deposit: "Acconto",
      invoice: "Fattura",
    },
    vat: "IVA",
    vatCode: "Partita IVA",
    withHoldingTax: "Ritenuta d'acconto",
    year: "Anno",
    yearlyPaymentByFirstExamination: "Annualità prima visita",
    yearlyPaymentLumpSum: "Annualità forfettaria",
    zipCode: "CAP",
  },
  navigation: {
    invoice: "Fatture",
  },
  buttons: {
    createCustomInvoice: "Crea personalizzata",
    createInvoice: "Crea fattura",
    createInvoiceXml: "Genera file XML",
    createVirtualInvoice: "Crea fattura virtuale",
    markAsEmitted: "Segna come emessa",
    markAsSent: "Segna come inviata",
    printInvoice: "Stampa fattura",
    printInvoiceDetail: "Stampa dettagli fattura",
    printInvoiceWithDetail: "Stampa fattura con dettagli",
    selectInvoiceElements: "Seleziona elementi",
    saveAndPrintInvoice: "Salva e stampa fattura",
  },
  toast: {
    error: {
      createInvoice: "Non è stato possibile creare la fattura",
      createInvoiceInvalidBroker: "I dati dell'intermediario non sono validi",
      createInvoiceInvalidCompany: "I dati dell'azienda non sono validi",
      createInvoiceMissingData: "Seleziona i valori per effettuare una ricerca",
      createInvoiceMissingParameter: "Parametro mancante",
      createInvoiceNoCompaniesWithLists: "Non sono state trovate aziende con i relativi listini",
      createInvoiceNoCompanyWithLists: "Non è stata trovata un'azienda con i relativi listini",
      createInvoiceNoCostList: "Non è stato trovato un listino costi da applicare",
      createInvoiceNoFirm: "Selezionare una ditta per proseguire",
      createInvoiceNoPriceList: "Non è stato trovato un listino prezzi da applicare",
      createInvoicePeriodTooLong: "Il periodo selezionato deve essere inferiore a un anno",
      createInvoiceStartDateAfterEndDate: "La data di inizio non può essere successiva alla data di fine",
      createInvoiceUndefinedBroker: "Intermediario non definito",
      createInvoiceUndefinedCompany: "Azienda non definita",
    },
    success: {},
    warning: {
      createInvoiceMissingData: "Seleziona i valori per effettuare una ricerca",
    },
  },
};
