import { add } from "date-fns";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { Firm } from "@/features/modules/firm/objects/Firm";
import { firmModel } from "@/features/modules/firm/models/FirmModel";
import { Invoice } from "@/features/modules/invoice/objects/Invoice";
import { InvoiceCompanyTotal } from "@/features/modules/invoice/objects/InvoiceCompanyTotal";
import { InvoiceDeadline } from "@/features/modules/invoice/objects/InvoiceDeadline";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";
import { Option } from "@/features/modules/option/objects/Option";
import { optionModel } from "@/features/modules/option/models/OptionModel";

export const calculateInvoiceTotals = async (invoice: Invoice): Promise<void> => {
  try {
    if (offlineModel.getOfflineState() === "offline") throw new Error("offlineModuleModelNotSaveable");

    if (invoice === undefined) throw new Error("createInvoiceMissingParameter");

    // load firm
    const firm: Firm = await firmModel.getSelectedFirm();
    // load option
    const option: Option = await optionModel.getDocument();

    // invoice rows
    invoice.subtotalVatExempted = 0; // imponibile esente IVA
    invoice.subtotalVatSubjected = 0; // imponibile soggetto IVA
    invoice.vat = 0; // iva
    invoice.emptyCompanyTotals();

    for (const invoicePriceRow of invoice.priceRows) {
      let subtotalVatSubjected = 0;
      let subtotalVatExempted = 0;
      if (invoicePriceRow.isVatApplied === true) {
        subtotalVatSubjected = invoicePriceRow.amount;
      } else {
        subtotalVatExempted = invoicePriceRow.amount;
      }
      invoice.subtotalVatExempted += subtotalVatExempted;
      invoice.subtotalVatSubjected += subtotalVatSubjected;
      if (invoicePriceRow.companyId !== undefined) {
        if (invoicePriceRow.companyId in invoice.companyTotals === false) {
          invoice.companyTotals[invoicePriceRow.companyId] = new InvoiceCompanyTotal(invoicePriceRow.companyId);
        }
        invoice.companyTotals[invoicePriceRow.companyId].subtotalVatExempted += subtotalVatExempted;
        invoice.companyTotals[invoicePriceRow.companyId].subtotalVatSubjected += subtotalVatSubjected;
      }
    }

    // totals
    invoice.subtotal = invoice.subtotalVatExempted + invoice.subtotalVatSubjected;

    const discountVatExempted: number = (invoice.discount * invoice.subtotalVatExempted) / invoice.subtotal;
    const discountVatSubjected: number = (invoice.discount * invoice.subtotalVatSubjected) / invoice.subtotal;

    invoice.subtotalVatExempted = Math.max(invoice.subtotalVatExempted - discountVatExempted, 0);
    invoice.subtotalVatSubjected = Math.max(invoice.subtotalVatSubjected - discountVatSubjected, 0);
    invoice.subtotal = invoice.subtotalVatExempted + invoice.subtotalVatSubjected;

    invoice.vat = DataHelpers.roundNumber(invoice.subtotalVatSubjected * (option.vatValue / 100), 2);
    invoice.total = invoice.subtotal + invoice.vat;

    // stamp
    invoice.stamp = 0;
    // && invoice.subtotalVatSubjected === 0) {
    if (invoice.subtotalVatExempted >= option.stampThreshold) {
      invoice.stamp = option.stampValue;
    }

    // withHoldingTax
    invoice.withHoldingTax = 0;
    if (firm.hasWithHoldingTax === true) {
      invoice.withHoldingTax = DataHelpers.roundNumber(
        (invoice.subtotalVatExempted + invoice.subtotalVatSubjected) * (option.withHoldingTaxValue / 100),
        2
      );
    }
    invoice.total += invoice.stamp - invoice.withHoldingTax;

    // deadlines
    for (const deadline of invoice.deadlines) {
      deadline.amount = DataHelpers.roundNumber(invoice.total * (deadline.amountPercentage / 100), 2);
      deadline.date = add(invoice.date, { days: deadline.daysToExpiration });
    }
    // fix last deadline if total is different from sum of deadlines
    if (invoice.total != invoice.deadlines.reduce((acc, deadline) => acc + deadline.amount, 0) && invoice.deadlines.length > 0) {
      const lastDeadline: InvoiceDeadline = invoice.deadlines[invoice.deadlines.length - 1];
      lastDeadline.amount += invoice.total - invoice.deadlines.reduce((acc, deadline) => acc + deadline.amount, 0);
    }
  } catch (error: unknown) {
    appFaultModel.catchAppError("InvoiceModel.calculateInvoiceTotals", { invoice }, error);
  }
};
